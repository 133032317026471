/* Header Container */
.sb-topnav.navbar {
    background-color: #343a40;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 0.75rem 1rem;
}

/* Navbar Brand */
.navbar-brand {
    font-size: 1.25rem;
    font-weight: 500;
    color: #ffffff;
}

/* Sidebar Toggle Button */
#sidebarToggle {
    color: #adb5bd;
    font-size: 1.2rem;
    padding: 0.25rem 0.5rem;
    transition: color 0.3s ease;
}

#sidebarToggle:hover {
    color: #ffffff;
}

/* Search Bar */
.form-inline .search-input {
    border: none;
    border-radius: 0.25rem 0 0 0.25rem;
    background-color: #495057;
    color: #fff;
}

.form-inline .search-input::placeholder {
    color: #ced4da;
}

.form-inline .search-btn {
    background-color: #495057;
    border: none;
    color: #fff;
    padding: 0.5rem;
    border-radius: 0 0.25rem 0.25rem 0;
    transition: background-color 0.3s ease;
}

.form-inline .search-btn:hover {
    background-color: #6c757d;
}

/* Navbar Icons */
.nav-item .nav-link {
    color: #adb5bd;
    font-size: 1.25rem;
    padding: 0.25rem;
    transition: color 0.3s ease;
}

.nav-item .nav-link:hover {
    color: #ffffff;
}

/* Dropdown Menu */
.dropdown-menu {
    background-color: #343a40;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
    color: #ced4da;
    padding: 0.5rem 1.5rem;
    transition: background-color 0.3s ease;
}

.dropdown-item:hover {
    background-color: #495057;
    color: #ffffff;
}

.dropdown-divider {
    border-top: 1px solid #495057;
}

/* User Icon */
.navbar-nav .nav-item .nav-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
