/* Sidebar Container */
.sidebar {
    background-color: #343a40;
    height: 100vh;
    padding: 80px 20px 20px 20px;
    color: #fff;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.sidebar-menu {
    flex: 1;
    overflow-y: auto;
}

/* Adjust padding for collapsed state */
.sidebar.collapsed {
    padding: 10px 5px;
}

/* Sidebar Links */
.sidebar-link {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin: 5px 0;
    color: #adb5bd;
    text-decoration: none;
    border-radius: 5px;
    transition:
        background-color 0.3s ease,
        color 0.3s ease;
}

.sidebar-link:hover,
.sidebar-link.active {
    background-color: #495057;
    color: #fff;
}

/* Adjust spacing for collapsed state */
.sidebar-link.collapsed-link {
    justify-content: center;
    padding: 10px;
}

.sidebar-icon {
    margin-right: 10px;
    font-size: 20px;
}

.sidebar-heading {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
    color: #ced4da;
    transition: margin 0.3s ease;
}

/* Adjust heading margin for collapsed state */
.sidebar-heading.collapsed-heading {
    margin-bottom: 20px;
    text-align: center;
}

/* Sidebar Text */
.sidebar-text {
    font-size: 16px;
}

/* Footer Section */
.sidebar-footer {
    margin-top: auto;
    padding: 15px 0;
    font-size: 14px;
    color: #adb5bd;
    border-top: 1px solid #495057;
    position: sticky;
    bottom: 0;
}

.sidebar-username {
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
}

.sidebar-branch {
    font-size: 14px;
    color: #ced4da;
    margin-top: 5px;
}

/* Icon Size */
.icon {
    font-size: 18px;
}
